<template>
  <div class="reserved-area-menu-wrapper">
    <div class="reserved-area-menu-notification-wrapper" v-if="this.$root.userInfo.roles && this.$root.userInfo.roles.length <= 1">
      <div class="container">
        <div class="reserved-area-menu-notification d-flex justify-content-start align-items-start">
          <div class="reserved-area-menu-notification-info">
            <p class="reserved-area-menu-notification-title">És Criador?</p>
            <p class="reserved-area-menu-notification-subtitle">Nós ajudamos a divulgar o teu trabalho</p>
            <router-link :to="{ name: 'reserved-area-creator-info'}" class="reserved-area-menu-notification-btn generic-btn white smaller2">Fala Connosco</router-link>
          </div>
          <button type="button" class="reserved-area-menu-notification-close" @click="closeCreatorRequestInfo">
            <closeIcon/>
          </button>
        </div>
      </div>
    </div>
    <div class="reserved-area-identification-block">
      <div class="container">
        <div class="reserved-area-identification">
          <button type="button" class="reserved-area-menu-close d-xl-none" @click="closeReservedAreaMenu">
            <closeIcon/>
          </button>
          <div class="d-flex justify-content-center align-items-center">
            <label class="relative">
              <input type="file" class="reserved-area-user-image-input" @change="userImageHandler">
              <div class="reserved-area-user-image">
                <img :src="this.$root.userInfo.photo ? this.$root.userInfo.photo : require('@/assets/img/user.jpg')" alt="">
              </div>
              <div class="reserved-area-identification-btn">
                <cameraIcon/>
              </div>
            </label>
          </div>
          <div class="mt-2 text-center">
            <span v-if="showMessage" class="pt-2 text-danger">{{errorMessage}}</span>
          </div>
          <div class="reserved-area-identification-heading d-flex justify-content-center align-items-center flex-column d-xl-none">
            <p class="reserved-area-identification-title">Olá {{ this.$root.userInfo.name }}</p>
            <div class="reserved-area-identification-credits d-flex justify-content-start align-items-center">
              <p>Plano Grátis</p>
              <p><span>0</span> Créditos</p>
            </div>
          </div>
          <div class="reserved-area-identification-btn-wrapper d-flex justify-content-center align-items-center flex-column">
            <router-link to="/reserved-area-credits" class="generic-btn red-gradient smaller2">Adicionar Créditos</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="reserved-area-menu">
      <div class="reserved-area-menu-group" v-if="this.$root.userInfo.roles && this.$root.userInfo.roles.length > 1">
        <div class="reserved-area-menu-group-title-wrapper">
          <div class="container">
            <p class="reserved-area-menu-group-title">Editor</p>
          </div>
        </div>
        <ul class="reserved-area-menu-group-list">
          <li>
            <router-link to="/reserved-area-creator-dashboard" class="reserved-area-menu-group-item">
              <div class="container">
                <div class="d-flex justify-content-between align-items-center">
                  <p>Dashboard</p>
                  <div class="reserved-area-menu-group-item-icon">
                    <chevronRight/>
                  </div>
                </div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/reserved-area-creator-personal-page" class="reserved-area-menu-group-item">
              <div class="container">
                <div class="d-flex justify-content-between align-items-center">
                  <p>Página Pessoal</p>
                  <div class="reserved-area-menu-group-item-icon">
                    <chevronRight/>
                  </div>
                </div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/reserved-area-creator-add-workout" class="reserved-area-menu-group-item">
              <div class="container">
                <div class="d-flex justify-content-between align-items-center">
                  <p>Adicionar treinos</p>
                  <div class="reserved-area-menu-group-item-icon">
                    <chevronRight/>
                  </div>
                </div>
              </div>
            </router-link>
          </li>
          <!--<li>
            <router-link to="/reserved-area-creator-calendar" class="reserved-area-menu-group-item">
              <div class="container">
                <div class="d-flex justify-content-between align-items-center">
                  <p>Calendário</p>
                  <div class="reserved-area-menu-group-item-icon">
                    <chevronRight/>
                  </div>
                </div>
              </div>
            </router-link>
          </li>-->
        </ul>
      </div>
      <div class="reserved-area-menu-group">
        <div class="reserved-area-menu-group-title-wrapper">
          <div class="container">
            <p class="reserved-area-menu-group-title">CONFIGURAÇÕES</p>
          </div>
        </div>
        <ul class="reserved-area-menu-group-list">
          <li>
            <router-link to="/reserved-area-personal-info" class="reserved-area-menu-group-item">
              <div class="container">
                <div class="d-flex justify-content-between align-items-center">
                  <p>Editar Perfil</p>
                  <div class="reserved-area-menu-group-item-icon">
                    <chevronRight/>
                  </div>
                </div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/reserved-area-change-password" class="reserved-area-menu-group-item">
              <div class="container">
                <div class="d-flex justify-content-between align-items-center">
                  <p>Palavra-passe</p>
                  <div class="reserved-area-menu-group-item-icon">
                    <chevronRight/>
                  </div>
                </div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/reserved-area-plans" class="reserved-area-menu-group-item">
              <div class="container">
                <div class="d-flex justify-content-between align-items-center">
                  <p>Planos</p>
                  <div class="reserved-area-menu-group-item-icon">
                    <chevronRight/>
                  </div>
                </div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/reserved-area-billing-data" class="reserved-area-menu-group-item">
              <div class="container">
                <div class="d-flex justify-content-between align-items-center">
                  <p>Dados Faturação</p>
                  <div class="reserved-area-menu-group-item-icon">
                    <chevronRight/>
                  </div>
                </div>
              </div>
            </router-link>
          </li>
          <!--          <li>
                      <router-link to="/reserved-area-notifications" class="reserved-area-menu-group-item">
                        <div class="container">
                          <div class="d-flex justify-content-between align-items-center">
                            <p>Notificações</p>
                            <div class="reserved-area-menu-group-item-icon">
                              <chevronRight/>
                            </div>
                          </div>
                        </div>
                      </router-link>
                    </li>-->
        </ul>
      </div>
      <div class="reserved-area-menu-group">
        <div class="reserved-area-menu-group-title-wrapper">
          <div class="container">
            <p class="reserved-area-menu-group-title">DOCUMENTAÇÃO</p>
          </div>
        </div>
        <ul class="reserved-area-menu-group-list">
          <li>
            <router-link to="/reserved-area-invoices" class="reserved-area-menu-group-item">
              <div class="container">
                <div class="d-flex justify-content-between align-items-center">
                  <p>Faturas</p>
                  <div class="reserved-area-menu-group-item-icon">
                    <chevronRight/>
                  </div>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="reserved-area-menu-group">
        <div class="reserved-area-menu-group-title-wrapper">
          <div class="container">
            <p class="reserved-area-menu-group-title">SAIBA MAIS</p>
          </div>
        </div>
        <ul class="reserved-area-menu-group-list">
          <li>
            <router-link to="/reserved-area-faqs" class="reserved-area-menu-group-item">
              <div class="container">
                <div class="d-flex justify-content-between align-items-center">
                  <p>FAQ’s</p>
                  <div class="reserved-area-menu-group-item-icon">
                    <chevronRight/>
                  </div>
                </div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/reserved-area-terms" class="reserved-area-menu-group-item">
              <div class="container">
                <div class="d-flex justify-content-between align-items-center">
                  <p>Termos e Condições</p>
                  <div class="reserved-area-menu-group-item-icon">
                    <chevronRight/>
                  </div>
                </div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/reserved-area-policy" class="reserved-area-menu-group-item">
              <div class="container">
                <div class="d-flex justify-content-between align-items-center">
                  <p>Política de Privacidade</p>
                  <div class="reserved-area-menu-group-item-icon">
                    <chevronRight/>
                  </div>
                </div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/reserved-area-about" href="" class="reserved-area-menu-group-item">
              <div class="container">
                <div class="d-flex justify-content-between align-items-center">
                  <p>Sobre BOX PT</p>
                  <div class="reserved-area-menu-group-item-icon">
                    <chevronRight/>
                  </div>
                </div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/reserved-area-contacts" class="reserved-area-menu-group-item">
              <div class="container">
                <div class="d-flex justify-content-between align-items-center">
                  <p>Entrar em Contacto</p>
                  <div class="reserved-area-menu-group-item-icon">
                    <chevronRight/>
                  </div>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import cameraIcon from "@/components/svg/camera-icon.vue";
import settingsIcon from "@/components/svg/settings-icon.vue";
import chevronRight from "@/components/svg/chevron-right.vue";
import closeIcon from "@/components/svg/close.vue";
import axios from "axios";
import qs from "qs";

export default {
  name: "reserved-area-menu",
  data() {
    return {
      photo: ( this.$root.userInfo.photo != null ) ? this.$root.userInfo.photo : require('@/assets/img/user.jpg'),
      showMessage: false,
      errorMessage: ''
    }
  },
  components: {
    cameraIcon,
    settingsIcon,
    chevronRight,
    closeIcon
  },
  methods: {
    closeReservedAreaMenu() {
      let element = document.getElementsByClassName("reserved-area-menu-wrapper")[0];

      if (element.classList.contains("open")) {
        element.classList.remove("open");
      }
    },
    userImageHandler(e) {
      let authKey = window.$cookies.get("authKey");
      let file = e.target.files[0];
      let filePath = URL.createObjectURL(file);
      let img = new Image();
      img.src = filePath
      var vm = this;
      img.onload = function() {
        let width = this.width
        let height = this.height
        if (width === 200 && height === 200) {
          vm.showMessage = false
          let formData = new FormData();
          formData.append('updateType', 'photo');
          formData.append('photo', file);

          this.photo = filePath;

          axios
              .post(process.env.VUE_APP_API_URL + 'customer', formData, {
                headers: {
                  Authorization: 'Bearer ' + authKey
                }
              })
              .then((response) => {
              })
              .catch((e) => {
                vm.errors.push(e);
                vm.showMessage = true;
                vm.errorMessage = 'An error occurred.';
              });
        } else {
          vm.errorMessage= 'Imagem tem de ter as seguintes dimensões 200X200 px'
          vm.showMessage = true
        }
      };
      this.$root.userInfo.photo = this.photo;
    },
    setData() {
      this.photo = this.$root.userInfo.photo
    },
    closeCreatorRequestInfo() {
      let element = document.getElementsByClassName("reserved-area-menu-notification-wrapper")[0];

      element.classList.add("closed");
    }
  },
  async created() {
    await this.$root.getCustomer()

    this.setData();
  }
}
</script>