<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="19" viewBox="0 0 9 19">
    <path fill="#B9B9B9" d="M12.499 23.972c-.249 0-.5-.074-.719-.23-.564-.395-.7-1.177-.301-1.74l5.295-7.515-5.295-7.517c-.399-.563-.263-1.343.301-1.74.566-.4 1.344-.263 1.741.3l5.804 8.235c.305.433.305 1.01 0 1.442l-5.804 8.235c-.242.346-.63.53-1.022.53" transform="translate(-1734 -217) translate(1723 212)"/>
  </svg>
</template>

<script>
export default {
  name: 'chevron-left'
}
</script>
