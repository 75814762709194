<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
    <path fill="#222221" d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-1.8c1.767 0 3.2-1.433 3.2-3.2 0-1.767-1.433-3.2-3.2-3.2-1.767 0-3.2 1.433-3.2 3.2 0 1.767 1.433 3.2 3.2 3.2z" transform="translate(-70 -164) translate(68 162)"/>
  </svg>
</template>

<script>
export default {
name: "camera-icon"
}
</script>

<style scoped>

</style>