<template>
  <div class="reserved-area-page">
    <div class="container">
      <div class="d-xl-flex">
        <div class="reserved-area-page-column">
          <reservedAreaMenu/>
        </div>
        <div class="reserved-area-page-column">
          <FormulateForm name="ask-role-form" class="ask-role-form" v-model="values" @submit="askRoleHandler">
            <div class="reserved-area-form-group-wrapper">
              <p class="section-title reserved-area-form-title">DADOS PESSOAIS</p>
              <div class="reserved-area-form-group">
                <div class="row">
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="text" name="first-name" v-model="firstName" disabled validation="required" placeholder="Insira o seu primeiro nome" label="Primeiro Nome"/>
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="text" name="last-name" v-model="lastName" disabled validation="required" placeholder="Insira o seu último nome" label="Último Nome"/>
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="text" name="email" v-model="email" disabled validation="required" placeholder="Insira o seu e-mail" label="E-mail"/>
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="text" name="phone" v-model="phone" validation="matches:/[0-9]/" placeholder="Insira o seu número de telefone" label="Telefone"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="reserved-area-form-group-wrapper">
              <p class="section-title reserved-area-form-title">Perfil</p>
              <div class="reserved-area-form-group">
                <div class="row">
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="select" name="access-request" v-model="requestType" label="Selecionar Pedido de Acesso" :options="{first: 'Sou Criador'}" validation="required"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="reserved-area-form-group-wrapper">
              <p class="section-title reserved-area-form-title">OUTRAS INFORMAÇÕES</p>
              <div class="reserved-area-form-group">
                <div class="row">
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="text" name="academic-training" v-model="academicTraining" placeholder="Insira a sua formação" label="Formação" value="Licenciatura em Desporto"/>
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="text" name="activity-years" v-model="activityYears" placeholder="" label="Anos em Atividade" value="2 anos"/>
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12">
                    <div class="form-field">
                      <FormulateInput type="textarea" name="message" v-model="message" placeholder="" label="Mensagem" rows="4" placeholder="Insira a sua mensagem"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="reserved-area-form-btn-wrapper d-flex justify-content-center align-items-center">
              <button type="submit" class="reserved-area-form-btn generic-btn red-gradient ">Guardar</button>
            </div>
          </FormulateForm>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reservedAreaMenu from "@/components/reserved-area/reserved-area-menu.vue";
import axios from "axios";
import qs from "qs";

export default {
  name: "reserved-area-index",
  data() {
    return {
      values: {},
      errors: [],
      submitted: false,
      firstName: this.$root.userInfo.name,
      lastName: this.$root.userInfo.last_name,
      email: this.$root.userInfo.email,
      phone: this.$root.userInfo.phone,
      requestType: 'first',
      academicTraining: '',
      activityYears: '',
      message: '',
      showMessage: false,
      formResponseMessage: '',
    }
  },
  components: {
    reservedAreaMenu,
  },
  methods: {
    askRoleHandler() {
      const requestBody = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phone: this.phone,
        requestType: this.requestType,
        degree: this.academicTraining,
        years: this.activityYears,
        message: this.message,
      };

      let authKey = window.$cookies.get("authKey");

      axios
          .post(process.env.VUE_APP_API_URL + 'customer/ask-role', qs.stringify(requestBody), {
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
            if (response.data == true) {
              this.$toast.success("Enviado com sucesso!", {position: 'bottom-right', hideProgressBar: true});
            }
          })
          .catch((e) => {
            this.$toast.error("Ups!Erro ao Enviar!", {position: 'bottom-right', hideProgressBar: true});
            this.errors.push(e);
            this.showMessage = true;
            this.formResponseMessage = 'An error occurred.';
          });
    },
  }
}
</script>